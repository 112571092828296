import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SharedService } from '../services/shared/shared.service';
var ScheduleCalenderPage = /** @class */ (function () {
    function ScheduleCalenderPage(sharedService, modalController) {
        this.sharedService = sharedService;
        this.modalController = modalController;
        this.dataType = 'js-date'; //? 'string' | 'js-date' | 'moment' | 'time' | 'object'
    }
    ScheduleCalenderPage.prototype.ngOnInit = function () {
    };
    ScheduleCalenderPage.prototype.closeModal = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.modalController.dismiss(data || undefined);
                return [2 /*return*/];
            });
        });
    };
    ScheduleCalenderPage.prototype.getDate = function (event) {
        this.selectedDate = event;
        console.log("selectedDate", this.selectedDate);
    };
    ScheduleCalenderPage.prototype.saveCalender = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var weekdayName, data;
            return tslib_1.__generator(this, function (_a) {
                console.log("selectedDate", this.selectedDate);
                if (this.selectedDate) {
                    weekdayName = new Date(this.selectedDate).toLocaleString("default", { weekday: "long" });
                    console.log("weekdayName", weekdayName);
                    data = {
                        date: this.selectedDate,
                        dayName: weekdayName
                    };
                    this.closeModal(data);
                }
                else {
                    this.closeModal();
                }
                return [2 /*return*/];
            });
        });
    };
    return ScheduleCalenderPage;
}());
export { ScheduleCalenderPage };
